import React from 'react';
import { imageUrl } from 'theme';
import Button from 'seedly-component-library/button-new';
import Dialog from 'seedly-component-library/dialog';

const EmailUnverifiedModal = props => {
  const {
    isEmailUnverifiedModalOpen = false,
    toggleEmailUnverifiedModal = () => {},
  } = props;

  return (
    <Dialog
      isOpen={isEmailUnverifiedModalOpen}
      onCloseClick={() => toggleEmailUnverifiedModal(false)}
    >
      <div className="flex flex-col w-full gap-8 items-center">
        <div className="flex flex-col w-full gap-3 items-center">
          <img
            src={imageUrl.success}
            height="84"
            width="84"
            alt="announcement"
          />
          <p className="text-xl font-bold">Please Verify Your Email</p>
          <p className="text-neutral-500 text-center">
            We have sent you another verification link to your email, please
            verify before you can login.
          </p>
        </div>
        <Button onClick={() => toggleEmailUnverifiedModal(false)}>
          Continue without logging in
        </Button>
      </div>
    </Dialog>
  );
};

export default EmailUnverifiedModal;
